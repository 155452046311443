<template>
  <div :class="$style.page">
    <template v-if="!isNotActiveProgram">
      <div :class="$style.button">
        <adw-button color="secondary" @click="finishBonusProgram"
          >Завершить спринт</adw-button
        >
      </div>

      <div :class="$style.container">
        <div :class="$style.infoWrapper">
          <span>Название: </span>
          <span :class="$style.info">{{ activeProgram.name }}</span>
        </div>
        <div :class="$style.infoWrapper">
          <span>Описание:</span>
          <div :class="$style.info">
            {{ activeProgram.description }}
          </div>
        </div>
        <div :class="$style.infoWrapper">
          <span>Статус:</span>
          <span :class="$style.info">{{
            getBonusProgramStatus(activeProgram.status)
          }}</span>
        </div>
        <div :class="$style.infoWrapper">
          <span>Дата начала:</span>
          <span :class="$style.info">{{
            formatDate(activeProgram.startsAt)
          }}</span>
        </div>
        <div :class="$style.infoWrapper">
          <span>Дата окончания:</span>
          <span :class="$style.info">
            {{ formatDate(activeProgram.endsAt) }}</span
          >
        </div>
        <div :class="$style.brandsWrapper">
          <span>Бренды участники:</span>
          <div :class="$style.cards">
            <BrandCard
              v-for="brand of brands"
              :key="brand.id"
              :name="brand.name"
              :image="brand.image"
            />
          </div>
        </div>
        <div :class="$style.levelsWrapper">
          <label :class="$style.levelLabel"
            >{{ getLevelLabel(activeProgram.levels[0].name) }}
          </label>
          <template v-for="prize of activeProgram.levels[0]?.prizes">
            <div :key="prize.prize">
              <div :class="$style.achieveAmountWrapper">
                <span :class="$style.levelInfo">Топ {{ prize.place }}</span>
              </div>

              <div :class="$style.cashbackWrapper">
                <span>Размер кешбэка %:</span>
                <span :class="$style.levelInfo">{{ prize.prize }}</span>
              </div>
            </div>
          </template>
        </div>
        <div
          v-for="(level, index) of activeProgram.levels?.slice(1)"
          :key="index"
          :class="$style.levelsWrapper"
        >
          <label :class="$style.levelLabel"
            >{{ getLevelLabel(level.name) }}
          </label>
          <div :class="$style.achieveAmountWrapper">
            <span>До следующего уровня:</span>
            <span :class="$style.levelInfo">{{
              activeProgram.levels[index + 1]?.achieveAmount
            }}</span>
          </div>

          <div :class="$style.cashbackWrapper">
            <span>Размер кешбэка %:</span>
            <span :class="$style.levelInfo">{{
              activeProgram.levels[index + 1]?.cashbackSize
            }}</span>
          </div>
        </div>
        <div :class="$style.ratingWrapper">
          <label :class="$style.ratingLabel">Статистика</label>
          <vue-good-table
            v-if="dealers?.length"
            :class="$style.table"
            :columns="columns"
            :rows="dealers"
            styleClass="vgt-table striped"
            fixed-header
          >
          </vue-good-table>
          <span v-else :class="$style.infoText"
            >К сожалению, на данный момент статистика отсутствует</span
          >
        </div>
      </div>
    </template>
    <span v-else :class="$style.infoText"
      >К сожалению, на данный момент нет ни одной активной бонусной
      программы</span
    >
  </div>
</template>

<script>
import delivery from '@/delivery'

import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import {
  BONUS_PROGRAM_LEVELS,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
} from '@/constants/bonusProgram'
import { formatDate } from '@/helpers/index.js'
import notifications from '@/mixins/notifications.js'

import BrandCard from '@/components/atoms/cards/BrandCard.vue'

export default {
  BONUS_PROGRAM_LEVELS,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
  components: { BrandCard },
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      activeProgram: {
        startsAt: new Date(),
        endsAt: new Date(),
      },
      brands: [],
      isNotActiveProgram: false,
      dealers: null,
    }
  },
  async created() {
    await this.getActiveBonusProgram()
    if (!this.isNotActiveProgram) {
      Promise.all([this.getBrandsList(), this.getActiveProgramStatistics()])
    }
  },
  computed: {
    columns: () => [
      {
        label: 'Название компании',
        field: 'dealerName',
        type: 'string',
      },
      {
        label: 'Сумма покупок',
        field: 'transactionSum',
        type: 'number',
      },
      {
        label: 'Кешбэк',
        field: 'value',
        type: 'number',
      },
      {
        label: 'Уровень',
        field: 'level',
        type: 'string',
      },
    ],
  },
  methods: {
    async getActiveBonusProgram() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getById(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        this.isNotActiveProgram = true
        return
      }

      this.activeProgram = value
    },
    async getBrandsList() {
      const query = {
        limit: 100000,
        bonusProgramId: this.$route.params.id,
      }

      const loading = this.$loading({
        lock: true,
      })

      const { value, error } = await delivery.AddwineCore.BrandsActions.getList(
        query,
      )

      loading.close()

      if (error) return

      this.brands = value.data
    },
    getBonusProgramStatus(status) {
      return BONUS_PROGRAM_STATUSES_DISPLAY[status] || ''
    },
    getLevelLabel(level) {
      return BONUS_PROGRAM_LEVELS[level]
    },
    formatDate(date) {
      return formatDate(date)
    },
    async finishBonusProgram() {
      const { error } =
        await delivery.AddwineCore.BonusProgramActions.finishById(
          this.$route.params.id,
        )

      if (error) {
        this.showNotification('Ошибка завершения бонусной программы', 'error')
        return
      }

      this.showNotification('Бонусная программа успешно завершена', 'success')
      this.$router.push(ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.LIST)
    },
    async getActiveProgramStatistics() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getActiveProgramStatisticsById(
          this.$route.params.id,
        )
      loading.close()
      if (error) return
      this.dealers = value?.map((e) => ({
        ...e,
        level: this.getLevelLabel(e.level),
      }))
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;

  .button {
    @include stickyWrapper;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .infoWrapper {
      display: flex;
      align-items: center;
      gap: 3rem;

      & > span:first-child {
        min-width: 9rem;
        white-space: nowrap;
      }

      .info {
        @include H200;
        max-width: 30rem;
      }
    }

    .brandsWrapper {
      display: flex;
      gap: 3rem;

      & > span:first-child {
        min-width: 9rem;
        white-space: nowrap;
      }

      .cards {
        display: flex;
        gap: 2.5rem;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .levelsWrapper {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 1rem;

      .levelLabel {
        min-width: 12rem;
      }
    }

    .achieveAmountWrapper,
    .cashbackWrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .levelInfo {
        @include H200;
      }
    }

    .ratingWrapper {
      margin-bottom: 2rem;
      .ratingLabel {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        @include H200;
      }
      .infoText {
        @include H200;
        height: 8vh;
      }
    }
  }

  .infoText {
    @include H200;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
</style>
